$(document).ready(function() {

    //
    // Raster-Lightbox (Bildergalerie)
    //
    function getActivePictureCount(Id){

        var ActivePicture = $('div#slickTrack-' + Id).find('div.slick-active').first().find('img');
        var ActivePictureCount = ActivePicture.attr('data-count');

        return ActivePictureCount;

    }



    function setRasterLightbox(){

        // Lightbox öffnen
        $( ".bund-open-rasterlightbox" ).each(function() {

            $(this).on("click keypress", function (event) {

                if (event.type === "click" || event.type === "keypress") {
                    event.preventDefault();
                    //console.log('tastaturclick ' + event.which);

                    if (event.which == 32 || event.which == 13 || event.which == 1) {
                        event.preventDefault();

                        var Button = $(this);
                        var Id = Button.attr('data-uid');
                        var Modal = $('div#rasterlightbox-' + Id);
                        var ModalHeader = $('div#rasterlightbox-h' + Id);
                        var ModalCloseButton = $('button#rasterlightbox-close-lightbox-c' + Id);
                        var Body = $('body');

                        // Modal anzeigen
                        if(Modal.hasClass('hidden')){
                            Modal.removeClass('hidden');
                            Body.addClass('overflow-y-hidden');
                            //var PosY = $(document).scrollTop(); //Scrollposition
                            ModalHeader.focus();

                            var ActivePictureCount = getActivePictureCount(Id);

                            setActivePicture(ActivePictureCount, Modal); // die Funktion befindet sich inline im Modal (wegen den Übersetzungen)

                        }
                    }
                }

            });
        });

        // Lightbox mit Button schließen
        $( ".close-rasterlightbox-button" ).each(function() {

            $(this).on("click", function (event) {
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    event.preventDefault();

                    var CloseButton = $(this);
                    var CloseButtonId = CloseButton.attr('data-uid');
                    var CloseButtonModal = $('div#rasterlightbox-' + CloseButtonId);
                    var CloseButtonBody = $('body');
                    var OpenButton = $('button#open-rasterlightbox-' + CloseButtonId);

                    // Modal schließen
                    if(CloseButtonModal.hasClass('hidden')===false){
                        CloseButtonModal.addClass('hidden');
                        CloseButtonBody.removeClass('overflow-y-hidden');
                        //$('html, body').scrollTop(PosY); //Scrollposition wieder herstellen
                        OpenButton.focus();
                    }
                }
            });
        });

        // Lightbox mit ESC schließen
        $(document).keydown(function(eventa) {

            var ModalDialoge = $('div.rasterlightbox-modal');
            var Body = $('body');

            ModalDialoge.each(function() {

                var Modal = $(this);
                var OpenButton = $('button#open-rasterlightbox-' + Modal.attr('data-uid'));

                if(Modal.hasClass('hidden')===false){
                    // 27=ESC
                    if (eventa.keyCode == 27) {
                        Modal.addClass('hidden');
                        Body.removeClass('overflow-y-hidden');
                        OpenButton.focus();
                    }
                }

            });

        });

        // Tab
        $(document).keydown(function(eventl) {

            var ModalDialoge = $('div.rasterlightbox-modal');

            ModalDialoge.each(function () {

                var Modal = $(this);
                var Id = Modal.attr('data-uid');
                var LastModalTabLink = Modal.find('button#rasterlightbox-close-lightbox-c' + Id);
                var FirstModalTabLink = Modal.find('div#rasterlightbox-h' + Id);
                var LastModalPictureTabLink = Modal.find('a').last();
                var FirstModalPictureTabLink = Modal.find('a').first();
                var AllPictureLinks = Modal.find('a');

                // Letztes Element - springe von letztem Tab-Element im Modal, zu erstem Tab-Element im Modal
                LastModalTabLink.keydown(function(eventlforwardtab) {
                    // Tab vor - springe zum Header
                    if (!eventlforwardtab.shiftKey && eventlforwardtab.keyCode == 9) {
                        eventlforwardtab.preventDefault();
                        FirstModalTabLink.focus();
                    }
                    // Tab zurück - springe zum letzten Bild
                    if (eventlforwardtab.shiftKey && eventlforwardtab.keyCode == 9) {
                        eventlforwardtab.preventDefault();
                        LastModalPictureTabLink.focus();
                    }
                });

                // Erstes Element
                FirstModalTabLink.keydown(function(eventlbackwardtab) {
                    // Tab vor - springe zu erstem Bild
                    if (!eventlbackwardtab.shiftKey && eventlbackwardtab.keyCode == 9) {
                        eventlbackwardtab.preventDefault();
                        FirstModalPictureTabLink.focus();
                    }
                    // Tab zurück - springe zu CloseButton
                    if (eventlbackwardtab.shiftKey && eventlbackwardtab.keyCode == 9) {
                        eventlbackwardtab.preventDefault();
                        LastModalTabLink.focus();
                    }
                });

                // Fallback wenn nichts fokussiert ist
                Modal.keydown(function(eventltab) {
                    if (Modal.is(':active')) {
                        if (eventltab.keyCode == 9) {
                            eventltab.preventDefault();
                            FirstModalTabLink.focus();
                        }
                    }
                });

            });

        });

        // Zur Grossansicht wechseln
        $( ".gotogross-rasterlightbox-piclink" ).each(function() {

            var RasterPicLink = $(this);
            var Id = RasterPicLink.attr('data-uid');
            var Modal = $('div#rasterlightbox-' + Id);
            var Body = $('body');
            var ActivePictureCount = RasterPicLink.attr('data-count');
            var Gross = $('div#grosslightbox-' + Id);
            var GrossHeader = $('div#grosslightbox-h' + Id);

            RasterPicLink.on('click keypress', function(event) {
                if (event.type === "click" || event.type === "keypress") {
                    event.preventDefault();
                    // 32=Leertaste, 13=Return, 1=Linke Maustaste
                    if (event.which == 32 || event.which == 13 || event.which == 1) {

                        // Id aktualisieren
                        ActivePictureCount = RasterPicLink.attr('data-count');

                        // Rasteransicht schließen
                        Modal.addClass('hidden');

                        // Grossansicht öffnen
                        Gross.removeClass('hidden');
                        GrossHeader.focus();

                        initGrossBildergalerie(ActivePictureCount);

                    }
                }

            });

        });

    }
    setRasterLightbox();

});